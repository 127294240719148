<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Index</router-link> |
      <router-link to="/home">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view class="bootstrap-wrapper" :data-theme="theme" />
  </div>
</template>

<script>
var hours = 1; // Reset when storage is more than (1=60mins)(0.1=6mins)
var now = new Date().getTime();
var setupTime = localStorage.getItem('_setupTime_');
if (setupTime == null) {
  localStorage.setItem('_setupTime_', now)
} else {
  if (now - setupTime > hours * 60 * 60 * 1000) {
    // localStorage.clear()
    // localStorage.removeItem("_accountData_");
    // localStorage.removeItem("_memberData_");
    // .......................................
    localStorage.removeItem("_languages_");
    localStorage.removeItem("_countries_");
    // localStorage.removeItem("_vocal_type_");
    // localStorage.removeItem("_com_type_");
    // localStorage.removeItem("_demo_cat_");
    localStorage.setItem('_setupTime_', now);
  }
}

export default {
  name: 'App',
  beforeCreate() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      console.log("%c It's a local server! ", 'background: #222; color: #bada55');
    } else {
      if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
    }
  },
  data() {
    return {
      theme: "light"
    }
  },
  created() {
    // console.log(this.$route.query.m_id);
    // console.log(this.$route.query.m_password);

    // // let uri = window.location.search.substring(1);
    // let uri = window.location.href;
    // let params = new URLSearchParams(uri);
    let params = new URLSearchParams(window.location.hash.split('?')[1]);
    let m_id = params.get("m_id");
    let m_password = params.get("m_password");
    let m_type = params.get("m_type");
    let p = params.get("p");
    if (m_id && m_password) {
      if (m_type == "special_account") {
        this.login_special_account(m_id, m_password, p);
      } else {
        this.auto_login_after_register(m_id, m_password);
      }
    }

    let free_account = params.get("free_account");
    let free_special_acct = params.get("free_special_acct");
    let e1 = params.get("e1");
    let e2 = params.get("e2");
    let e3 = params.get("e3");
    let m_email = e1 + "@" + e2 + "." + e3;
    let m_name = params.get("m_name");
    let m_lname = params.get("m_lname");
    let m_sex = params.get("m_sex");
    let last4 = params.get("last4");
    if (free_account == "true") {
      this.create_free_account(m_email, m_password, m_name, m_lname, m_sex, last4)
    }
    if (free_special_acct == "true") {
      this.create_free_special_acct(m_email, m_password, m_name, m_lname, m_sex, last4)
    }
  },
  // beforeMount(){
  //   // Opera 8.0+
  //   const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  //   // Firefox 1.0+
  //   const isFirefox = typeof InstallTrigger !== 'undefined';
  //   // Safari 3.0+ "[object HTMLElementConstructor]"
  //   const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
  //   // Internet Explorer 6-11
  //   const isIE = /*@cc_on!@*/false || !!document.documentMode;
  //   // Edge 20+
  //   const isEdge = !isIE && !!window.StyleMedia;
  //   // Chrome 1 - 71
  //   const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  //   // Blink engine detection
  //   const isBlink = (isChrome || isOpera) && !!window.CSS;
  //
  //   if(!isChrome){
  //     setTimeout(()=>{
  //       this.$toast.warning(`Failed to copy referral code`, { position: 'top-right' })
  //     }, 5000);
  //   }
  // },
  mounted() {
    if (!this.age_range.length) this.get_age_range();
    if (!this.union_status.length) this.get_union_status();
    if (!this.delivery_cap.length) this.get_delivery_cap();
    if (!this.ethnicities.length) this.get_ethnicities();
    if (!this.languages.length) this.get_languages();
    if (!this.countries.length) this.get_countries();
    if (!this.vocal_type.length) this.get_vocal_type();
    if (!this.com_type.length) this.get_com_type();
    if (!this.demo_cat.length) this.get_demo_cat();
    if (!this.accents.length) this.get_accents();
  },
  methods: {
    async login_special_account(m_id, m_password, p = null) {
      var url = this.api + 'login/special_login_free_account/' + this.secret;
      var data = new FormData();
      data.set('m_id', m_id);
      data.set('m_password', m_password);
      this.Axios.post(url, data)
        .then((response) => {
          if (response.data.error == 0) {
            let accountData = response.data.data;

            this.save_token(accountData);
            this.$swal("Successful Login", "", "success");
            if (p) {
              this.redirect(`/accounts/voiceover_projects?p=${p}`);
            } else {
              this.redirect(`/accounts`);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async auto_login_after_register(m_id, m_password) {
      console.log(m_id, m_password);
      var url = this.api + 'login/member_login_after_register/' + this.secret;
      var data = new FormData();
      data.set('m_id', m_id);
      data.set('m_password', m_password);
      this.Axios.post(url, data)
        .then((response) => {
          if (response.data.error == 0) {
            let accountData = response.data.data;

            this.save_token(accountData);
            this.$swal("Successful Login", "", "success");
            this.redirect("/members");
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    create_free_account(m_email, m_password, m_name, m_lname, m_sex, last4) {
      var url = this.api + 'Register/create_free_account/' + this.secret;
      var data = new FormData();
      data.set("secret", this.secret);
      data.set("m_email", m_email);
      data.set("m_password", m_password);
      data.set("m_name", m_name);
      data.set("m_lname", m_lname);
      data.set("m_sex", m_sex);
      data.set("last4", last4);
      this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            this.$swal("Successful", "Your email has been confirmed", "success").then(() => {
              let m_id = response.data.data.id;
              let m_password = response.data.data.password;
              this.auto_login_free_account(m_id, m_password);
            })
          } else {
            // this.$swal("",response.data.data,"error").then(()=>{
            //   location.reload();
            // })
            this.$swal("", response.data.data, "error");
          }
        })
        .catch((error) => {
          console.error(error);
          this.$swal("", "", "warning");
        })
    },
    auto_login_free_account(m_id, m_password) {
      var url = this.api + 'login/personal_login_free_account/' + this.secret;
      var data = new FormData();
      data.set('m_id', m_id);
      data.set('m_password', m_password);
      this.Axios.post(url, data)
        .then((response) => {
          if (response.data.error == 0) {
            let accountData = response.data.data;

            this.save_token(accountData);
            // this.redirect("/personal");
            this.redirect("/personal/update_profile");
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    create_free_special_acct(m_email, m_password, m_name, m_lname, m_sex, last4) {
      var url = this.api + 'Register/create_free_special_acct/' + this.secret;
      var data = new FormData();
      data.set("secret", this.secret);
      data.set("m_email", m_email);
      data.set("m_password", m_password);
      data.set("m_name", m_name);
      data.set("m_lname", m_lname);
      data.set("m_sex", m_sex);
      data.set("last4", last4);
      this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            this.$swal("Successful", "Your email has been confirmed", "success").then(() => {
              let m_id = response.data.data.id;
              let m_password = response.data.data.password;
              this.auto_login_free_special_acct(m_id, m_password);
            })
          } else {
            this.$swal("", response.data.data, "error");
          }
        })
        .catch((error) => {
          console.error(error);
          this.$swal("", "", "warning");
        })
    },
    auto_login_free_special_acct(m_id, m_password) {
      var url = this.api + 'login/special_login_free_account/' + this.secret;
      var data = new FormData();
      data.set('m_id', m_id);
      data.set('m_password', m_password);
      this.Axios.post(url, data)
        .then((response) => {
          if (response.data.error == 0) {
            let accountData = response.data.data;

            this.save_token(accountData);
            this.redirect("/accounts/update_profile");
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
  }
}
</script>

<style>
@import url(//fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap);

#app {
  font-family: 'Prompt', sans-serif;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  align-items: center !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.swal-text {
  text-align: center !important;
}

.swal-footer {
  text-align: center !important;
}

.float-right {
  float: right !important;
}

.multiselect__tag {
  background: #3273dc !important;
}

.multiselect__tag-icon:after {
  color: #fff !important;
}

.multiselect__content {
  margin: 0 !important;
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 767px) {

  /* (max-width: 600px) */
  .hidden-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block !important;
  }
}

.photo-album {
  border-radius: 9px;
  width: 100%;
  height: 175px !important;
  object-fit: cover !important;
}

.cut-text {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  /* display: none !important; */
  /* width: 160px;  */
  /* height: 1.2em;  */
}
</style>
