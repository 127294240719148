import Vue from 'vue'
import App from './App.vue'

import Func from './Func.vue'
Vue.mixin(Func);

import Paypal_plan from './Paypal_plan.vue'
Vue.mixin(Paypal_plan);

import Stripe_plan from './Stripe_plan.vue'
Vue.mixin(Stripe_plan);

import VueRouter from 'vue-router'
import router from './router'
Vue.use(VueRouter)

import Meta from 'vue-meta'
Vue.use(Meta)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

// import 'bulma/css/bulma.css'
import './assets/bulma@1.0.0/css/bulma.css'
import 'bootstrap-grid-only-css/dist/css/bootstrap-grid.min.css';
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

import './font-awesome'; // Import the Font Awesome configuration

import Axios from 'axios';
Vue.prototype.Axios = Axios;
Vue.prototype.secret = `0499BBxxCv`;

const PRODUCTION = true;
if (PRODUCTION) {
  // Vue.prototype.vocadd = "http://66.29.138.193/voicecastnetworks/public/";
  // Vue.prototype.api = "https://voicecastnetworks.com/fetch/?/";
  Vue.prototype.vocadd = "https://public.voicecastnetworks.com/";
  Vue.prototype.api = "https://api.voicecastnetworks.com/?/";
} else {
  Vue.prototype.vocadd = "http://localhost:8000/vocadd_backend/";
  Vue.prototype.api = "http://localhost:8000/VoicecastNetworks/api/";
  // Vue.prototype.vocadd = "http://192.168.1.3:8000/vocadd_backend/";
  // Vue.prototype.api = "http://192.168.1.3:8000/vocadd_app/api/";
}

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: '/assets/lazy.png',
  error: '/assets/user.jpg',
  attempt: 1
})

import VueLazyLoadVideo from 'vue-lazyload-video'
Vue.use(VueLazyLoadVideo)

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

import VueSwal from 'vue-swal'
Vue.use(VueSwal)

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
