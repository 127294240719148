<script>
export default {
  data(){
    return {
      plans: [
        {
          id: 'P-1KR23150W6589560UL7C74BA',
          name: 'Monthly',
          price: 1.25,
          per: '/month',
          features: [
            '$1.25 Per Month (+7% tax)',
            '(No Commitment Monthly plan)',
          ],
        },
        {
          id: 'P-6K886336NT1075946L7C74XI',
          name: 'Yearly',
          price: 10,
          per: '/year',
          features: [
            '$10.00 Per Year (+7% tax)',
            '(Save 33.3% Paying Yearly)',
          ],
        }
      ],
      paypal_plans: [],
    }
  },
};
</script>
